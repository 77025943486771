// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');



/*
@font-face {
  font-family: "Oxygen";
  src: url("/assets/fonts/oxygen.woff2"); //mine
}*/

* {
  font-family: "Roboto" !important;
  // font-weight: 200!important;
}

html,
body {
  background-color: transparent;
  margin-top: 0 !important;
}

// fix per iphone notch
ion-content {
  padding-top: constant(safe-area-inset-top);
  /* Status bar height on iOS 11.0 */
  padding-top: env(safe-area-inset-top);
  /* Status bar height on iOS 11+ */
  margin-bottom: constant(safe-area-inset-bottom);
  /* Status bar height on iOS 11.0 */
  margin-bottom: env(safe-area-inset-bottom);
  /* Status bar height on iOS 11+ */
}

html {
  //background: url('/assets/imgs/Background.jpg') 0 0/100% 100% no-repeat;
}


@supports (-webkit-touch-callout: none) {

  /* CSS specific to iOS devices */
  ion-fab {
    margin-bottom: 50px
  }

}

.tabs-inner {
  flex: unset !important;
}
